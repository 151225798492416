import React from "react";
import Immutable from "immutable";

import Error from "js/common/views/error";
import {IconButton, TextButton} from "js/common/views/inputs/buttons";
import {SimpleTest} from "js/admin/kpis/edit-kpis/tabs/test";
import {
  addJoinPathsBackIntoFilter,
  filterPattern,
  getTableEntityTypeToGroupingEntityType,
  MessageOnlyTab,
  removeComponentLinksFromEntityPath,
  stripJoinPathsFromFilter
} from "js/admin/kpis/edit-kpis/utils";
import AutocompleteTextArea from "js/common/views/inputs/autocomplete-textarea";
import {indexBy} from "js/common/utils/collections";
import * as Kpis from "js/common/kpis";
import ConfigOverview from "js/admin/kpis/edit-kpis/tabs/config-overview";
import {SingleEntityPathPicker} from "js/common/views/inputs/entity-path-pickers";
import {ParentWarning} from "js/admin/kpis/edit-kpis/warnings";

const unsupportedEntityMessage = "Fast filters are only yet available for Candidate, CV Sent and Generic Entities." +
    " Follow the entity path to provide a fast filter for a supported entity, otherwise create a slow filter.";

const supportedEntityRegex = new RegExp(/^(ENTITY\d+|CV_SENT|CANDIDATE|PLACEMENT|PLACEMENT_SPLIT)/);

const SingleFilterEditor = React.memo((
    {
      filterIndex,
      filter,
      rootGroupingEntity,
      typeToGroupingEntity,
      tableEntityTypeToGroupingEntityType,
      onChange,
      onDelete
    }) => {

  const [entityPath, setEntityPath] = React.useState(() => {
    const initialPathFromFilter = getInitialPathFromCoreFilter(tableEntityTypeToGroupingEntityType, filter);
    let pathToUse;
    if (initialPathFromFilter.isEmpty()) {
      pathToUse = Immutable.List.of(rootGroupingEntity);
    } else {
      pathToUse = initialPathFromFilter;
    }
    return removeComponentLinksFromEntityPath(pathToUse, typeToGroupingEntity);
  });

  const [lookupColumnsForPath, setLookupColumnsForPath] = React.useState(new Immutable.List());
  const entityColumnIdToLookupColumn = React.useMemo(
      () => indexBy(c => c.get("entityColumnId"), lookupColumnsForPath),
      [lookupColumnsForPath]);

  React.useEffect(() => {
    const entity = entityPath.last();
    if (supportedEntityRegex.test(entity)) {
      const coreTypeEntity = entityPath.last() + "_CORE_TYPE";
      Kpis.loadEntityColumnsForEntityPaths(Immutable.List.of(entityPath))
          .then(columns => {
            const lookupColumns = columns
                .filter(c => {
                  const joinPath = new Immutable.List(c.get("joinPathStr").split(","));
                  const isFromCoreTypeTable = joinPath.last() === coreTypeEntity;
                  const isFromTypeTable = joinPath.get(joinPath.size - 2) === coreTypeEntity;
                  return isFromCoreTypeTable || isFromTypeTable;
                })
                .map(c => new Immutable.Map({
                  joinPathStr: c.get("joinPathStr"),
                  entityColumnId: c.get("entityColumnId"),
                  displayLabel: c.get("label"),
                  value: "[" + c.get("entityColumnId") + " " + c.get("label") + "]",
                  autoCompleteChar: ":"
                }))
                .sortBy(c => c.get("displayLabel"));
            setLookupColumnsForPath(lookupColumns);
          });
    }
  }, [entityPath]);

  const handleEntityPathChange = React.useCallback(entityPathStr => {
    setEntityPath(oldEntityPath => {
      let newEntityPath;
      if (entityPathStr) {
        newEntityPath = new Immutable.List(entityPathStr.split(","));
      } else {
        newEntityPath = Immutable.List.of(rootGroupingEntity);
      }
      if (!Immutable.is(newEntityPath, oldEntityPath)) {
        onChange(filterIndex, "");
      }
      return newEntityPath;
    });
  }, [rootGroupingEntity]);

  let content;
  if (!supportedEntityRegex.test(entityPath.last())) {
    content = <Error text={unsupportedEntityMessage} type="warn" style={{marginTop: 0}} />;
  } else if (lookupColumnsForPath.isEmpty()) {
    content = "Loading columns..";
  } else {
    content = <AutocompleteTextArea
        delayInMillis={1000}
        lookupEntries={lookupColumnsForPath}
        onChange={newFilter => onChange(
            filterIndex,
            addJoinPathsBackIntoFilter(newFilter, entityColumnIdToLookupColumn))}
        value={stripJoinPathsFromFilter(filter, entityColumnIdToLookupColumn) || ""}
        label="Core Type Filter. Type ':' to show columns you can select from" />;
  }

  return (
      <div style={{display: "flex", marginBottom: "1rem"}}>
        <div style={{flex: "auto", height: "max-content"}}>
          <SingleEntityPathPicker
              entityPath={entityPath}
              typeToGroupingEntity={typeToGroupingEntity}
              onChange={handleEntityPathChange} />
          {content}
        </div>
        <IconButton
            icon="times"
            type="alert"
            hoverType="alert"
            onClick={() => onDelete(filterIndex)}
            size="large" />
      </div>
  );
});

const getInitialPathFromCoreFilter = (tableEntityTypeToGroupingEntityType, filter) => {
  let regexMatches;
  let tablePathMatches = new Immutable.Set();
  while ((regexMatches = filterPattern.exec(filter)) !== null) {
    tablePathMatches = tablePathMatches.add(regexMatches[1]);
  }

  const longestPath = tablePathMatches.maxBy(path => path.count);
  if (longestPath) {
    return new Immutable.List(longestPath
        .split(",")
        .map(tableEntity => tableEntityTypeToGroupingEntityType.get(tableEntity)));
  } else {
    return new Immutable.List();
  }
};

const managedKeyPaths = Immutable.fromJS([["queryParams", "coreTypeFilters"]]).toSet();

const CoreTypeFiltersEditor = React.memo((
    {
      combinedKpi,
      idToEntityColumn,
      typeToGroupingEntity,
      kpiIsParent,
      kpiIsChild,
      testResult,
      onTest,
      rootGroupingEntity,
      filters,
      onFiltersChange,
      rootChangedWarning,
      queryType,
      requiresExplanationUpdate,
      getExplanationWarning
    }) => {
  const [showConfig, setShowConfig] = React.useState(false);

  const tableEntityTypeToGroupingEntityType = React.useMemo(
      () => getTableEntityTypeToGroupingEntityType(typeToGroupingEntity),
      [typeToGroupingEntity]);

  const onFilterChange = React.useCallback(
      (index, filter) => onFiltersChange(filters.set(index, filter)),
      [onFiltersChange, filters]);

  const onFilterDelete = React.useCallback(
      (index) => onFiltersChange(filters.delete(index)),
      [onFiltersChange, filters]);

  if (queryType === "GENERIC_ENTITY" && !rootGroupingEntity) {
    return <MessageOnlyTab message={"You must pick a generic entity."} />;
  } else {
    return <div style={{padding: "1rem"}}>
      {kpiIsParent && <ParentWarning />}
      {rootChangedWarning && <Error text={rootChangedWarning} type="warn" style={{marginTop: 0}} />}
      {requiresExplanationUpdate && getExplanationWarning()}
      {filters.map((filter, index) =>
          <SingleFilterEditor
              key={index}
              filterIndex={index}
              filter={filter}
              idToEntityColumn={idToEntityColumn}
              typeToGroupingEntity={typeToGroupingEntity}
              tableEntityTypeToGroupingEntityType={tableEntityTypeToGroupingEntityType}
              rootGroupingEntity={rootGroupingEntity}
              onChange={onFilterChange}
              onDelete={onFilterDelete} />)}

      <div style={{display: "flex", marginBottom: "1rem"}}>
        <TextButton
            type="primary"
            icon="plus"
            label="ADD FILTER"
            onClick={() => onFiltersChange(filters.push(""))} />
        <SimpleTest
            style={{marginRight: "1rem"}}
            testResult={testResult}
            onTest={onTest} />
        {kpiIsChild && <TextButton
            label={`${showConfig ? "Hide" : "Show"} Inherited Config`}
            onClick={() => setShowConfig(prev => !prev)} />}
      </div>

      {kpiIsChild && showConfig &&
          <div style={{display: "flex", marginBottom: "1rem"}}>
            <ConfigOverview
                columnNameToMap={Immutable.Map({"Value": combinedKpi})}
                keyPaths={managedKeyPaths} />
          </div>}

    </div>;
  }
});

export default CoreTypeFiltersEditor;